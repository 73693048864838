import React from 'react';
import { NOT_FOUND } from 'staticData/NotFound';
import { BODY1, Button, GridContainer, H2 } from '@tuunetech/tuune-components';
import { Link } from 'components/shared';
import { NotFoundContainer, ContentGridItem } from './styles';

export const NotFoundLayout: React.FC = () => {
  return (
    <NotFoundContainer>
      <GridContainer>
        <ContentGridItem xs={12} md={8} lg={6} xl={4} $offsetMd={1}>
          <H2>{NOT_FOUND.title}</H2>
          <BODY1>{NOT_FOUND.message}</BODY1>
          <Button>
            <Link to={NOT_FOUND.link} underline="none">
              {NOT_FOUND.buttonText}
            </Link>
          </Button>
        </ContentGridItem>
      </GridContainer>
    </NotFoundContainer>
  );
};

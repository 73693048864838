import styled from 'styled-components';
import { BODY1, Container, GridItem, H2 } from '@tuunetech/tuune-components';

export const ContentGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NotFoundContainer = styled(Container)`
  padding-top: 136px;
  padding-bottom: 84px;

  ${H2} {
    margin-bottom: 30px;
  }

  ${BODY1} {
    margin-bottom: 20px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    ${ContentGridItem} {
      display: block;
    }
  }
`;

import React from 'react';
import { PageProps } from 'gatsby';
import {
  componentResolverFromMap,
  UnknownRecord,
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews';
import { Layout } from 'components/shared';
import { NotFoundLayout } from 'components/NotFound';
import { linkResolver } from 'utils/linkResolver';
import Page from 'templates/page';

const NotFound: React.FC<PageProps<UnknownRecord>> = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <NotFoundLayout />
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(NotFound, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || '',
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: Page,
    }),
  },
]);
